/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import "./app/app.scss";
@import "./app/utils/utility.css";

.confirmationAlert {
  background-color: red;
}

.customModal {
  .modal-wrapper {
    width: 90%;
  }
}

.customDatePicker {
  --ion-background-color: transparent;
  ion-content {
    width: 90%;
    top: 25%;
    left: 5%;
  }
}

// user account css start
.userAccountWrapper {
  h1.title {
    font-weight: 600;
    font-size: 20px;
    color: var(--ion-color-dark);
    margin-bottom: 8px;
  }
  ion-text {
    font-weight: 400;
    font-size: 14px;
  }
  ion-grid {
    width: 100%;
  }
  .action-button {
    --border-radius: 8px;
    --box-shadow: none !important;
    box-shadow: none !important;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-transform: initial;
    }
  }
  .white {
    height: 100%;
    display: block;
    vertical-align: middle;
  }
  #dobInput {
    position: relative;
  }
  #dobInput .suffix-icon {
    position: absolute;
    top: 30%;
    right: 2%;
  }
  ion-input,
  ion-select,
  ion-textarea {
    min-width: 100%;
    width: 100%;
    background: #f9f9f9;
    border: 1px solid grey;
    border-radius: 6px;
    --box-shadow: none;
    --placeholder-color: cvar(--ion-color-medium);
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 14px;
  }
  .input-label {
    font-weight: 600;
    color: #151522;
    margin-bottom: 10px;
  }
  .caption {
    color: #151522;
  }
  .link {
    text-decoration: none;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
  }
  .errors-container {
    font-size: 12px;
    font-weight: 500;
    color: var(--ion-color-danger);
    margin-top: 4px;
    animation: fadeIn 0.5s;
  }
  .back-btn {
    background: rgba(36, 42, 56, 0.4);
    border-radius: 50%;
    ion-icon {
      font-size: 24px;
      color: #ffffff;
    }
  }
  .userAccountForm {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    margin-top: 50px;
  }
  .userBiographyForm,
  .userPhotoIdVerificationForm,
  .userAccountReviewForm {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    padding: 0px;
    // max-height: auto;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

// user account css end
// global
ion-spinner {
  width: 75px;
  height: 75px;
  --color: (--ion-color-primary);
}

body::-webkit-scrollbar {
  display: none;
}

// scroll inline styles

// ion-content {

//   --offset-bottom: auto !important;
//   --overflow: hidden;
//   overflow: auto;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}
ion-content{
  --ion-background-color:transparent;
}
.conversations-popover {
  --height: 50%;
  --width: 70%;
}

.add-connection-popover {
  top: -10% !important;
  --backdrop-opacity: 0.6;
  --width: 95%;
}

.chat-settings-popover {
  --width: 60%;
}

.connection-list-menu-popover {
  --width: 300px;
}

.connection-menu-popover {
  --width: 90%;
}

.connection-popover {
  --offset-y: -300px !important;
  --offset-x: -35% !important;
  --width: 90%;
}

.send-invite-popover {
  --width: 90%;
}

.story-card-popover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px !important;
  margin: 0px !important;
}

.seeking-profile-popover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px !important;
  margin: 0px !important;
}

.query-popover {
  max-height: 500px;
  --width: 70% !important;
}

.small-modal {
  .modal-wrapper {
    position: absolute;
    bottom: 0;
    min-height: initial;
    top: initial;
    height: 50%;
  }
}

.admin-add-element-popover {
  --width: 90%;
}

.menu-popover {
  --width: 60%;
}

.item-menu-popover {
  --width: 40%;
}

.modal-fullscreen {
  .popover-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
ion-item {
  --background: none;
  opacity: 1;
  --min-height: auto;
}
.button-native {
  -–padding-start: 0px;
  -–padding-end: 0px;
}
.content-desktop.setting-page {
  padding: 0 7px;
}

.cursor-pointer {
  cursor: pointer;
}


@media screen and (min-width: 769px) and (max-width: 1199px) {
  .content-desktop {
    width: 70%;
    margin: 0px auto;
  }
  ion-router-outlet {
    background: #f5f5f5;
  }
}
@media screen and (min-width: 1200px) and (max-width: 5100px) {
  .content-desktop {
    width: 45%;
    margin: 0px auto;
  }
  ion-router-outlet {
    background: #f5f5f5;
  }
}
